import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import arrowUp from '../images/arrow-up.svg'

interface IncreaseScorecardProps {
  iconSrc: string;
  title: string;
  text: string;
  direction: string;
}

const IncreaseScorecard: FC<IncreaseScorecardProps> = ({ iconSrc, title, text, direction}) => {
  return (
    <IncreaseCard>
        <React.Fragment>
          <ArrowAndTitle>
            { direction === "up" ? 
              (<img src={arrowUp} alt="increase" />) : 
              (<ArrowDown src={arrowUp} alt="decrease" />)
            }
          <p>{title}</p>
          </ArrowAndTitle>
          <IncreaseCardText>
            {text}
          </IncreaseCardText>
          <ScorecardIcon src={iconSrc} alt="icon" />
        </React.Fragment>
    </IncreaseCard>
  );
};

export default IncreaseScorecard;

export const IncreaseCard = styled.div`
    background-color: #0064B4;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    margin-bottom: 1.5rem;
    position: relative;
    overflow: hidden;
    width: 100%;
    z-index: 9;
`;

export const ArrowAndTitle = styled.div`
display:flex;
color: #fff;
align-items: center;
margin-bottom: 1rem;
> img {
  width:40px;
  filter: invert(1); 
// white color filter to change the color of arrows on cards
}
> p {
  font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 300;
    padding: 0px 0 0 0.3rem;
    margin-bottom: 0;
}
`;

export const ArrowDown = styled.img`
  transform: rotate(180deg);

`;

export const IncreaseCardText = styled.div`
    color: #fff;
    color: rgb(255, 255, 255);
    font-weight: 300;
    line-height: 1.3;
    font-size: 15px;
    padding: 1rem 4rem 2rem 0;
    @media only screen and (max-width: 600px) {
      font-size: 16px;
      padding: 1rem 4rem 1rem 0;
    }
`;

export const ScorecardIcon = styled.img`
    position: absolute;
    width: 98px;
    bottom: -5px;
    right: -5px;
    @media only screen and (max-width: 600px) {
      width: 68px;
      bottom: 0px;
      right: 0px;
    }
`;