import React, { ReactNode, HTMLAttributes } from "react";
import styled from "styled-components";

interface ScorecardsRowProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
    width?: string;
}

const Row = styled.div<ScorecardsRowProps>`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin: 2rem 0;
    flex-wrap: wrap;
    @media only screen and (max-width: 768px) {
        flex-direction: column
    }
    > * {
        display: flex;
        width: 32%;
        ${(props) => props.width && `width: ${props.width};`}
        
        @media only screen and (max-width: 992px) {
            width: 48%;
        }
        @media only screen and (max-width: 768px) {
            width: 100%
        }
    }
  

`;

const ScorecardsRow: React.FC<ScorecardsRowProps> = ({ children, width }) => {
    return (
        <Row width={width}>
            {React.Children.map(children, child => (
                <div className="scorecard-container">{child}</div>
            ))}
        </Row>
    );
};

export default ScorecardsRow;