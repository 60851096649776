import React from "react";
import styled from 'styled-components';

import { Header } from "../components/index";
import { Navigation } from "../components/index";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "../styles/global";
import { theme } from "../styles/theme";
import {last_updated, commercial__market_data, commercial__sales} from "../sections/commercial"
import {pdr__product, pdr_bd} from "../sections/pdr"
import { pdr_research  } from "../sections/software_data_q"
import {pplfin__people, pplfin__finance, thankyou} from "../sections/people_finance"

const Home = (props: object) => {
    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <Header>
                    <Navigation></Navigation>
                </Header>
                <PageWrapper>
                    {last_updated()}
                    <></>
                    {commercial__market_data()}
                    <></>
                    {commercial__sales()}
                    <></>
                    {pdr__product()}
                    <></>
                    {pdr_research()}
                    <></>
                    {pdr_bd()}
                    <></>
                    {pplfin__people()}
                    <></>
                    {pplfin__finance()}
                    <></>
                    {thankyou()}
                </PageWrapper>
            </ThemeProvider>
        </div>
    )
}

export default Home;

const PageWrapper = styled.div`
    width: 80%;
    margin-top: 145px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 60%;
    margin: 145px auto 0;
    overflow:hidden;
    @media only screen and (max-width: 768px) {
        max-width: none;
        width: 100%;
    }
    @media only screen and (min-width: 769px) and (max-width: 1200px) {
        max-width: none;
        width: 80%;
    } 
`