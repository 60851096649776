import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface ScoreCardProps {
  iconSrc: string;
  value: string;
  metric: string;
  fontsize?: string;
  scorecard_style?: string;
  sup_val?: string
  sup_metric?: string;
  increaseValue?: string;
  decreaseValue?: string
}


const ScoreCard: FC<ScoreCardProps> = ({ iconSrc, value, metric, fontsize, scorecard_style, sup_val, sup_metric, increaseValue, decreaseValue}) => {
  return (
    <Scorecard>
      <ScoreCardIconContainer>
      {iconSrc != "" ? (
        <ScorecardIcon src={iconSrc} alt="icon" />
      ) : (
        null
      )}
      </ScoreCardIconContainer>
      {scorecard_style === "col2" ? (
        <div>
          <Value>{value}<Sup>{sup_val}</Sup>
              {increaseValue ? (
                <IncreasedValue>{increaseValue}</IncreasedValue>
                  ) : (
                    null
              )}
              {decreaseValue ? (
                <DecreasedValue>{decreaseValue}</DecreasedValue>
                  ) : (
                    null
              )}
          </Value>
          <Metric fontsize={fontsize}>{metric}<Sup>{sup_metric}</Sup>
          </Metric>
        </div>
      ) : (
        <React.Fragment>
          <Value>{value}<Sup>{sup_val}</Sup></Value>
          
          <MetricContainer>
          
            <Metric fontsize={fontsize}>{metric}<Sup>{sup_metric}</Sup></Metric>
          </MetricContainer>
        </React.Fragment>
      )}
    </Scorecard>
  );
};


ScoreCard.defaultProps = {
  fontsize: '14px',
  scorecard_style: "col2" // col3 or col2
}

export default ScoreCard;

export const Scorecard = styled.div`
    background-color: #f4f4f4;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    margin-bottom: 1.5rem;
    position: relative;
    overflow: hidden;
    width: 100%;
    z-index: 9;
`;

export const ScoreCardIconContainer = styled.div`
    width: 90px;
    position: absolute;
    top: -11px;
    right: -12px;
    background: #00A06E;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
`;

export const ScorecardIcon = styled.img`
    width: 40px;
    flex-shrink: 0;
`;

export const Value = styled.div`
  font-size: 35px;
  font-weight: 600;
  color: #000;
  position: relative;
  display: flex;
  align-items: start;
`;

export const IncreasedValue = styled.div`
  font-size: 1rem;
  display: inline-flex;
  padding: 2px 10px;
  color: #33a06e;
  &::before {
    content: "↑";
  }
`;

export const DecreasedValue = styled.div`
  font-size: 1rem;
  display: inline-flex;
  padding: 2px 10px;
  color: #33a06e;
  &::before {
    content: "↓";
  }
`;

export const Metric = styled.span<{ fontsize?: string }>`
  font-size: ${props => props.fontsize || "15px"};
  display: block;
  color: #000;
  margin-top: 1rem;
`;

const Sup = styled.sup`
  font-size: 0.6em;
  vertical-align: super;
`;

export const MetricContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;
