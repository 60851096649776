import React, { ReactNode, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { useNavigate, useLocation } from "react-router-dom"

export interface IAuthRouteProps { children: ReactNode };

const AuthRoute: React.FunctionComponent<IAuthRouteProps> = props => {
    const { children } = props
    const auth = getAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const AuthCheck = onAuthStateChanged(auth, (user) => {
            setLoading(false);
            if (user) {
                setLoading(false);
                console.log("authorized")
            } else {
                console.log('unauthorized');
                navigate('/login');
            }
        });

        return () => AuthCheck();
    }, [auth]);

    if (loading) return <p>loading...</p>;
    return <>{children}</>
};

export default AuthRoute;