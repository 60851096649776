import React, { ReactNode } from "react";
import styled from 'styled-components';

// TypeScript interface for the component props
interface ImageProps {
    children: ReactNode;
}

// Header component
const ShowImage: React.FC<ImageProps> = ({ children }) => {
    return (
        <ImageContainer>
           <h1>THANK YOU</h1>
        </ImageContainer>
    );
};

export default ShowImage;

const ImageContainer = styled.div`
    background-image: url(${process.env.PUBLIC_URL}/Clap.gif);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    width: 100%;
    height: 500px;
    margin: 3rem 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    @media only screen and (max-width: 768px) {
        height: 360px;
        border-radius: 0px;
        margin-bottom: 0;
    }
    > h1 {
        font-weight: 300;
        color: #000;
        text-align: center;
        font-size: 45px;
        line-height: 0.9;
        background: rgb(247 247 247 / 70%);
        border-radius: 50%;
        width: 255px;
        height: 255px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: flex-end;
        outline: rgb(247 247 247 / 70%) solid 7px;
        border: 2px solid #000;
        position: absolute;
        top: -103px;
        left: -12px;
        line-height: 1;
        padding: 0 0 2rem 0;
        @media only screen and (max-width: 768px) {
            width: 220px;
            height: 220px;
            font-size: 30px;
            padding: 2rem;
        }
    }
`;