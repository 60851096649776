import React from "react";
import { GlobalStyles } from "./styles/global";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { config } from "./config/config";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AuthRoute from "./components/AuthRoute";
import Home from "./routes/home"
import Login from "./routes/login"



export const app = initializeApp(config.firebaseConfig)
export const analytics = getAnalytics(app);

function App() {
  return (
    <BrowserRouter>
      <GlobalStyles />
      <Routes>
        <Route
          path="/" element={
            <AuthRoute>
              <Home />
            </AuthRoute>
          }
        />
        <Route path="/login" element={<Login />} />
      </Routes>

    </BrowserRouter>
  );
}
export default App;
