import React, { useRef, useEffect } from 'react';
import Highcharts, { Chart } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import styled from 'styled-components';
import AnnotationsModule from 'highcharts/modules/annotations';


AnnotationsModule(Highcharts);

interface OriginalSeriesData {
  name: string;
  data: number[];
}

interface BarChartProps {
  chartId: string;
  title: string;
  unit: string;
  data: OriginalSeriesData[];
}

const Container = styled.div`
    width: 100%;
    margin: 0 auto 4rem;
    height: 100%;
`;

interface Annotation {
    xAxis: number;
    yAxis: number;
    x: number;
    y: number;
    text: string;
    bgColor?: string;
    borderColor?: string
  }

const StackedBarChart: React.FC<BarChartProps> = ({ chartId, title, unit, data }) => {
    const annotations: Annotation[] = [
        {
          xAxis: 0,
          yAxis: 0,
          x: 1.5, // This should match a category index (e.g., Q2)
          y: 38, // Value on yAxis where the annotation should appear
          text: `<h2 style="font-weight: 500; font-size: 15px;">HIGHLIGHTS</h2>`,
          bgColor: '#fff',
        },
        {
          xAxis: 0,
          yAxis: 0,
          x: 0, // This should match a category index (e.g., Q2)
          y: 18, // Value on yAxis where the annotation should appear
          text: 'Technology Repository',
          bgColor: '#fafafa',
          borderColor: '#eee'
        },
        {
          xAxis: 0,
          yAxis: 0,
          x: 0, // This should match a category index (e.g., Q2)
          y: 14, // Value on yAxis where the annotation should appear
          text: 'Product scraper',
          bgColor: '#fafafa',
          borderColor: '#eee'
        },
        {
          xAxis: 0,
          yAxis: 0,
          x: 0, // This should match a category index (e.g., Q2)
          y: 10, // Value on yAxis where the annotation should appear
          text: 'Proprietary Technology Categorisation System',
          bgColor: '#fafafa',
          borderColor: '#eee'
        },
        {
          xAxis: 0,
          yAxis: 0,
          x: 1, // This should match a category index (e.g., Q2)
          y: 21, // Value on yAxis where the annotation should appear
          text: 'Polymer database',
          bgColor: '#fafafa',
          borderColor: '#eee'
        },
        {
          xAxis: 0,
          yAxis: 0,
          x: 1,  // This should match a category index (e.g., Q2)
          y: 17, // Value on yAxis where the annotation should appear
          text: 'TEA application',
          bgColor: '#fafafa',
          borderColor: '#eee'
        },
        {
          xAxis: 0,
          yAxis: 0,
          x: 2, // This should match a category index (e.g., Q2)
          y: 30, // Value on yAxis where the annotation should appear
          text: 'Plasticisers database',
          bgColor: '#fafafa',
          borderColor: '#eee'
        },
        {
          xAxis: 0,
          yAxis: 0,
          x: 2, // This should match a category index (e.g., Q2)
          y: 26, // Value on yAxis where the annotation should appear
          text: 'Benchling integration',
          bgColor: '#fafafa',
          borderColor: '#eee'
        },
        {
          xAxis: 0,
          yAxis: 0,
          x: 3, // This should match a category index (e.g., Q2)
          y: 40, // Value on yAxis where the annotation should appear
          text: `Technical Data Sheet pipeline`,
          bgColor: '#fafafa',
          borderColor: '#eee'
        },
        {
          xAxis: 0,
          yAxis: 0,
          x: 3, // This should match a category index (e.g., Q2)
          y: 36, // Value on yAxis where the annotation should appear
          text: 'Advanced Search Engine',
          bgColor: '#fafafa',
          borderColor: '#eee'
        }
      ];
      
    const chartRef = useRef<Chart | null>(null);
    const options: Highcharts.Options = {
      
        chart: {
          type: 'column',
        },
        title: {
          text: title
        },
        xAxis: {
          categories: ['Q1', 'Q2', 'Q3', 'Q4']
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Q functionalities completed'
          }
        }
      };
    useEffect(() => {
      // Define a type for the transformed data
      type TransformedData = { software: number; ai: number; data: number };

      // Initialize an empty array for the transformed data
      const transformedData: TransformedData[] = [];

      data.forEach((series) => {
        series.data.forEach((point, index) => {
          // Ensure the accumulator has an object for this index
          if (!transformedData[index]) {
            transformedData[index] = { software: 0, ai: 0, data: 0 };
          }
          // Use a type guard to assign the data to the correct key
          if (series.name === 'software' || series.name === 'ai' || series.name === 'data') {
            transformedData[index][series.name] = point;
          }
        });
      });

      chartRef.current = Highcharts.chart(chartId, {
        chart: {
          type: 'column',
          marginTop: 20
        },
        title: {
          text: title
        },
        xAxis: {
          categories: ['Q1 2023', 'Q2 2023', 'Q3 2023', 'Q4 2023']
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Q functionalities completed'
          },
          stackLabels: {
            enabled: false,
            style: {
              color: 'blue'
            }
          }
        },
        tooltip: {
          shared: true,
          valueSuffix: ' ' + unit
        },
        plotOptions: {
          series: {
            stacking: 'normal'
          }
        },
        credits: {
          enabled: false
        },
        annotations: annotations.map(({ xAxis, yAxis, x, y, text, bgColor, borderColor }) => ({
          draggable: '',
          crop: true,
          labelOptions: {
            shape: 'rect',
            borderRadius: 5,
            backgroundColor: '#fafafa',
            borderColor: '#eee',
            verticalAlign: 'top',
            overflow: 'justify',
            y: 0,
            style: {
              fontSize: '12px',
              fontWeight: '300',
              width: 200,
              whiteSpace: 'break-spaces',
            },
            
          },
          labels: [
            {
              point: {
                xAxis,
                yAxis,
                x,
                y,
              },
              text,
              backgroundColor: bgColor,
              borderColor: borderColor
            },
          ],
        })), 
        series: [
            {
              name: 'Software',
              data: transformedData.map(d => d.software),
              type: 'column',
              color: '#00A06E'
            },
            {
              name: 'AI',
              data: transformedData.map(d => d.ai),
              type: 'column',
              color: '#F88F6F'
            },
            {
              name: 'Data',
              data: transformedData.map(d => d.data),
              type: 'column',
              color: '#0064b4'
            }
          ]
        });
      // }
  
      // Cleanup function
      return () => {
        chartRef.current?.destroy();
      };
    }, [chartId, title, unit, data]);
  
    
  return (
    <Container id={chartId}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Container>
  );
};
  
  export default StackedBarChart;