import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface ScoreCardProps {
    firstLine: string;
    secondLine: string;
    value: string;
    metric: string;
    fontsize?: string;
}


const ScoreCardExp: FC<ScoreCardProps> = ({ firstLine, secondLine, value, metric, fontsize }) => {
    return (
        <Scorecard>
            <React.Fragment>
                <Text>{firstLine}</Text>
                <div>
                    <Value>{value}</Value>
                    <Metric fontsize={fontsize}>{metric}</Metric>
                </div>
                <Text>{secondLine}</Text>
            </React.Fragment>
        </Scorecard>
    );
};


ScoreCardExp.defaultProps = {
    fontsize: '14px',
}

export default ScoreCardExp;

export const Scorecard = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  width=50%;
`;

export const ScorecardIcon = styled.img`
  width: 50px;
  margin-right: 10px;
`;

export const Value = styled.span`
  font-size: 48px;
  font-weight: 500;
`;

export const Metric = styled.span<{ fontsize?: string }>`
  font-size: ${props => props.fontsize || "16px"};
  color: #aaa;
  padding-left: 15px;
`;

export const Text = styled.span`
  font-size: 16px;
  color: #aaa;
  padding-left: 15px;
`;

export const MetricContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;
