import React, { useEffect, useRef } from 'react';
import Highcharts, { Options } from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import accessibility from 'highcharts/modules/accessibility';
import styled from 'styled-components';

highchartsMore(Highcharts);
solidGauge(Highcharts);

const StyledDonutChartContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin-top: 5%;
margin-left: 25%;

@media (max-width: 1200px) {
    margin-left: 5%; 
  }
`;

type DonutChartProps = {
    title?: string;
    data: { name: string; y: number; color: string }[];
    chartId?: string; // Add a chart ID prop
    titleX?:number;
    titleY?:number;
};

const sum = (props: DonutChartProps) => {
    const value = props.data.reduce(
        (acc, curr) => {
            if (typeof curr.y === 'number') {
                acc += curr.y;
            }
            return acc;
        },
        0
    );
    const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR',
    }).format(value);

    return (
        formattedValue
    )
}



const DonutChart = ({ title, data, chartId, titleX, titleY }: DonutChartProps) => {
    const chartRef = useRef<Highcharts.Chart>();
    if (!title) {
        title = sum({ title, data }).toString();

    }
    useEffect(() => {
        chartRef.current = Highcharts.chart(`chart-${chartId}`, { // Add unique chart ID
            chart: {
                type: 'pie',
                plotShadow: false,
                margin: [0,0,50,0] // top, right, bottom, left
                
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    innerSize: '75%',
                    borderWidth: 6,
                    borderColor: undefined,
                    slicedOffset: 20,
                    dataLabels: {
                        enabled: true,
                        distance: 30, // Adjust distance of labels from plot
                        style: {
                            fontSize: '18px',
                            fontFamily: 'Inter',
                            textOutline: 'none', // Remove border color
                            fontWeight: "400",
                        },
                        format: '{point.name}',
                    },
                },
            },

            title: {
                align:"center",
                verticalAlign: "middle",
                x: titleX,
                y: titleY,
                text: title,
                style: {
                    fontSize: '30px',
                    fontFamily: 'Inter',
                    textOutline: 'none', // Remove border color
                    fontWeight: "400",
                },
                
            },
            legend: {},
            series: [
                {
                    type: 'pie',
                    data: data.map((item) => ({
                        name: item.name,
                        y: item.y,
                        color: item.color,
                    })),
                },
            ],
        });
    }, [data, title, chartId, titleX, titleY]); // Add chartId to useEffect dependencies


    return (
        <StyledDonutChartContainer>
            <div id="chart-container-${chartId}" >
                <div id={`chart-${chartId}`} />
            </div>

        </StyledDonutChartContainer>
    );
};

DonutChart.defaultProps = {
    titleX: 0,
    titleY: 0,
  }

export default DonutChart;
