import React from 'react';
import styled from 'styled-components';
import CollapsibleSection from '../components/collapsible';
import {
  Wrapper,
  Row,
  Column,
  Box,
  Text,
  Chart,
  SectionTitle,
  SectionSubTitle,
  SectionSubSubTitle,
  StyledParagraph,
  Highlight,
  UnorderedList,
  TwoChartsSection,
  Annotation,
  CompaniesLogos,
  GraphAndCards,
} from '../components/sectionElements';
import BarChart from '../components/charts/barchart';
import LineChart from '../components/charts/lineChart';
import DonutChart from '../components/charts/donut';
import { ScoreCard, ScorecardsRow } from '../components/index';
import * as data from '../data';
import ShowImage from '../components/image';
import europe from '../images/024-europe.svg';
import female from '../images/023-girl-power.svg';
import experience from '../images/022-rating.svg';
import resignation from '../images/belongings.svg';
import mondalez from '../images/jobsLogos/mndlz.png';
import mondi from '../images/jobsLogos/mondi.png';
import tetrapak from '../images/jobsLogos/tetrapak.png';
import michelman from '../images/jobsLogos/michelman.png';
import stora from '../images/jobsLogos/stora.png';
import huhtamaki from '../images/jobsLogos/huhtamaki.png';
import beiersdorf from '../images/jobsLogos/beiersdorf.png';
import mckinsey from '../images/jobsLogos/mckinsey.png';
import accenture from '../images/jobsLogos/accenture.png';
import fraunhofer from '../images/jobsLogos/fraunhofer.png';
import ibm from '../images/jobsLogos/ibm.png';
import sonoco from '../images/jobsLogos/sonoco.png';
import gartner from '../images/jobsLogos/gartner.png';
import infarm from '../images/jobsLogos/infarm.png';
import tableau from '../images/jobsLogos/tableau.png';
import danone from '../images/jobsLogos/danone.png';
import salary from '../images/019-salary.svg';
import fume from '../images/calendar-white.svg';
import burn from '../images/fire-white.svg';
import tangible from '../images/tangible-asset.svg';
import intangible from '../images/intangible-asset.svg';
import ebitda from '../images/ebitda.svg';

export const pplfin__people = () => {
  return (
    <Wrapper id="section4">
      <SectionTitle>Finance and People</SectionTitle>
      <SectionSubTitle>Our Team</SectionSubTitle>
      <ScorecardsRow width="24%">
        <ScoreCard
          iconSrc={experience}
          value={data.people__numbers.active_employees.toString()}
          increaseValue="5"
          metric="active employees"
        ></ScoreCard>
        <ScoreCard
          iconSrc={europe}
          value={data.people__numbers.nationalities_represented.toString()}
          increaseValue="2"
          metric="nationalities represented on the team"
        ></ScoreCard>
        <ScoreCard
          iconSrc={female}
          value={data.people__numbers.self_described_female}
          increaseValue="8"
          metric="team members self-described as female"
        ></ScoreCard>
        <ScoreCard
          iconSrc={resignation}
          value={data.people__numbers.leavers_since_inception.toString()}
          increaseValue={data.people__numbers.leavers_since_inception_delta.toString()}
          metric="FTEs leaving since inception of the business"
        ></ScoreCard>
      </ScorecardsRow>

      <CollapsibleSection title="Our team's Previous Experience" iconColor="#00A06E">
        {/* <SectionSubTitle>Our Team&apos;s Previous Experience</SectionSubTitle> */}
        <CompaniesLogos>
          <div className="div1">
            <img src={mondalez} alt="Mondalez" />
          </div>
          <div className="div2">
            <img src={michelman} alt="Mondalez" />
          </div>
          <div className="div3">
            <img src={ibm} alt="Mondalez" />
          </div>
          <div className="div4">
            <img src={mondi} alt="Mondalez" />
          </div>
          <div className="div5">
            <img src={huhtamaki} alt="Mondalez" />
          </div>
          <div className="div6">
            <img src={accenture} alt="Mondalez" />
          </div>
          <div className="div7">
            <img src={stora} alt="Mondalez" />
          </div>
          <div className="div8">
            <img src={tetrapak} alt="Mondalez" />
          </div>
          <div className="div9">
            <img src={sonoco} alt="Mondalez" />
          </div>
          <div className="div10">
            <img src={mckinsey} alt="fraunhofer" />
          </div>
          <div className="div11">
            <img src={fraunhofer} alt="ibm" />
          </div>
          <div className="div12">
            <img src={beiersdorf} alt="sonoco" />
          </div>
          <div className="div13">
            <img src={infarm} alt="infarm" />
          </div>
          <div className="div14">
            <img src={danone} alt="danone" />
          </div>
          <div className="div15">
            <img src={tableau} alt="tableau" />
          </div>
          <div className="div16">
            <img src={gartner} alt="gartner" />
          </div>
        </CompaniesLogos>
      </CollapsibleSection>

      <SectionSubTitle>Leadership</SectionSubTitle>
      <ScorecardsRow width="48%">
        <ScoreCard
          iconSrc={experience}
          value={data.people__numbers.leadership_years.toString()}
          metric="years collective experience"
        ></ScoreCard>

        <ScoreCard
          iconSrc={female}
          value={data.people__numbers.blt_female_ratio.toString()}
          metric="self-describe as female"
        ></ScoreCard>
      </ScorecardsRow>
    </Wrapper>
  );
};

export const pplfin__finance = () => {
  return (
    <Wrapper>
      <SectionSubTitle>Our Finances</SectionSubTitle>
      <SectionSubSubTitle>Total Funding Raised to Date</SectionSubSubTitle>
      <GraphAndCards>
        <DonutChart data={data.finance_raised} title="€11.5M" chartId="raised_donut" titleX={-5} titleY={0} />
      </GraphAndCards>
       {/* <ScorecardsRow width="100%"></ScorecardsRow> */}
      <ScorecardsRow width="32%">
        <ScoreCard
          iconSrc={fume}
          value={data.finance__numbers.current_fume_date.toString()}
          metric="Current fume date without additional cash injection"
        ></ScoreCard>
        <ScoreCard
          iconSrc={burn}
          value={data.finance__numbers.monthly_burn_rate.toString()}
          metric="Average Monthly Burn H2 2023"
        ></ScoreCard>
        <ScoreCard
          iconSrc={salary}
          value={data.finance__numbers.salaries_paid_in_last_quarter.toString()}
          metric="Salaries paid in Q3 2023"
        ></ScoreCard>

        <ScoreCard
          iconSrc={ebitda}
          value={data.finance__numbers.ebitda.toString()}
          metric="EBITDA year to date"
        ></ScoreCard>
        <ScoreCard
          iconSrc={tangible}
          value={data.finance__numbers.tangible.toString()}
          metric="Tangible Assets - October 2023"
        ></ScoreCard>
        <ScoreCard
          iconSrc={intangible}
          value={data.finance__numbers.intangible.toString()}
          metric="Intangible Assets - October 2023"
        ></ScoreCard>
      </ScorecardsRow>
    </Wrapper>
  );
};

export const thankyou = () => {
  return <ShowImage>Thank youuu</ShowImage>;
};
