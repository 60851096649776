import React from 'react';
import styled from 'styled-components';
import CollapsibleSection from '../components/collapsible';
import {
  Wrapper,
  Row,
  TimeArrow,
  ArrowLine,
  ArrowHead,
  TimeArrowWrapper,
  Column,
  SectionTitle,
  Annotation,
  SectionSubTitle,
  StyledParagraph,
  Highlight,
  UnorderedList,
  Thermometer,
  ThermoTextLine1,
  ThermoTextLine2,
  ThermoTextLine3,
  ThermoTextLine4,
  ThermoTextLine5,
  ThermoTextLine6,
  ThermoTextLine7,
  ThermoTextLine8,
  ThermoTextLine9,
  ThermoTitle,
  ThermoValues,
  InternalSubtitle,
  HalfCardsHalfImage,
  ThermoTextLine10,
} from '../components/sectionElements';
import FunnelChart from '../components/charts/funnel';
import ProductPerformance from '../components/charts/productperformance';
import BarChart from '../components/charts/barchart';
import StageCurve from '../components/charts/curve';
import { ScoreCard, ScorecardsRow, IncreaseScorecard } from '../components/index';
import { ImageContainer } from '../components/imageContainer';
import ArrowScoreCard, { ArrowScorecardsContainer, ArrowTitle } from '../components/arrowedScorecards';
import * as data from '../data';
import partners from '../images/010-cooperation.svg';
import suppliers from '../images/011-packages.svg';
import quality from '../images/high-quality.svg';
import world from '../images/save-the-world.svg';
import recycle from '../images/recycle.svg';
import factory from '../images/factory.svg';
import air from '../images/o2-white.svg';
import water from '../images/water-drop-white.svg';
import IconTextCard from '../components/iconTextCard';
import bakery from '../images/016-bakery-1.svg';
import frozenFood from '../images/015-frozen-goods-1.svg';
import dishwasher from '../images/010-dishwasher-1.svg';
import pills from '../images/012-pills-1.svg';
import coffee from '../images/011-nuts-1.svg';
import dogFood from '../images/017-pet-food.svg';
import flour from '../images/018-flour.svg';
import snackBar from '../images/019-protein-bar.svg';
import tea from '../images/020-tea-cup.svg';
import grainBag from '../images/021-grain-bag.svg';
import dryFood from '../images/025-packaging.svg';
import acorn from '../images/033-acorn.svg';
import sugar from '../images/036-sugar.svg';
import chocolateBar from '../images/034-chocolate-bar.svg';
import ecoBag from '../images/eco-bag.svg';

export const pdr__product = () => {
  return (
    // Usage example:
    <Wrapper id="section2">
      <SectionTitle>Product Development & Research</SectionTitle>
      <CollapsibleSection title="Our Impact">
    
        <ScorecardsRow>
          <IncreaseScorecard 
            iconSrc={world}
            title="Reduce"
            direction="down" 
            text="Leakage into the environment"
          ></IncreaseScorecard>
          <IncreaseScorecard
            iconSrc={ecoBag}
            title="replace"
            direction="down"
            text="17.5 tonnes fossil plastic avoided with our first scale up run of Glasswing"
          ></IncreaseScorecard>
          <IncreaseScorecard
            iconSrc={recycle}
            title="Increase"
            direction="up"
            text="Circularity-recyclability and recyled content"
          ></IncreaseScorecard>
        </ScorecardsRow>
      </CollapsibleSection>

      <SectionSubTitle>Product Development & Scale Up Plan for 2024</SectionSubTitle>
      {/* <ArrowTitle>
                    <p>Collected</p>
                    <p>Assessed</p>
                    <p>Prototyped</p>
                    <p>Pilot Scale</p>
                    <p>Market Ready</p>
            </ArrowTitle> */}
      <ArrowScorecardsContainer>
        <StageCurve  />
        {/* stage curve component */}

        {/* <ArrowScoreCard
                iconSrc={lightbulb}
                value={data.pdr__pipeline[0].value.toString()}
                mobileTitle="Collected"
                metric={data.pdr__pipeline[0].metric.toString()} index={5} backgroundColor="#FA6432"></ArrowScoreCard>
            <ArrowScoreCard
                iconSrc={patent}
                value={data.pdr__pipeline[1].value.toString()}
                mobileTitle="Assessed"
                metric={data.pdr__pipeline[1].metric.toString()} index={4} backgroundColor="#ffc700"></ArrowScoreCard>
            <ArrowScoreCard
                iconSrc={technology2}
                value={data.pdr__pipeline[2].value.toString()}
                mobileTitle="Prototyped"
                metric={data.pdr__pipeline[2].metric.toString()} index={3} backgroundColor="#96D232"></ArrowScoreCard>
             <ArrowScoreCard
                iconSrc={material}
                value={data.pdr__pipeline[3].value.toString()}
                mobileTitle="Pilot Scale"
                metric={data.pdr__pipeline[3].metric.toString()} index={2} backgroundColor="#46B950"></ArrowScoreCard>
            <ArrowScoreCard
                iconSrc={parquet}
                value={data.pdr__pipeline[4].value.toString()}
                mobileTitle="Market Ready"
                metric={data.pdr__pipeline[4].metric.toString()} index={1} backgroundColor="#0064B4"></ArrowScoreCard> */}
      </ArrowScorecardsContainer>
      {/* <TimeArrowWrapper>
                <TimeArrow>Avg. time to prototype: 100 days</TimeArrow>
                <ArrowLine />
                <ArrowHead />
            </TimeArrowWrapper> */}
      <></>
      <br />
      <br />
      <SectionSubTitle>Technology Readiness Level</SectionSubTitle>

      <Thermometer>
        <ThermoTitle>
          <p>Research</p>
          <p>Development</p>
          <p>Deployment</p>
        </ThermoTitle>
        <ThermoValues>
          <div className="one">1</div>
          <div className="two">2</div>
          <div className="three">3</div>
          <div className="four">4</div>
          <div className="five">5</div>
          <div className="six">6</div>
          <div className="seven">7</div>
          <div className="eight">8</div>
          <div className="nine">9</div>
        </ThermoValues>
        <ThermoTextLine1> Chitosan </ThermoTextLine1>
        <ThermoTextLine2> Thermocell </ThermoTextLine2>
        <ThermoTextLine3> Lignin </ThermoTextLine3>
        <ThermoTextLine4> Cyanophycin </ThermoTextLine4>
        <ThermoTextLine5> Alpine Blend </ThermoTextLine5>
        <ThermoTextLine6> Heat Seal 2 </ThermoTextLine6>
        <ThermoTextLine7> Primer 12 </ThermoTextLine7>
        <ThermoTextLine8> I’m green™ BIO-BASED </ThermoTextLine8>
        <ThermoTextLine9> Heat Seal 1 </ThermoTextLine9>
        <ThermoTextLine10> BioChic </ThermoTextLine10>
      </Thermometer>

      <br />
      <br />

      <CollapsibleSection title="Our Product Performance">
        <ScorecardsRow>
          <ScoreCard
            iconSrc={water}
            value={data.pdr__numbers.achieved_wvtr.toString()}
            metric="Highest WVTR achieved to date (ambient)"
          ></ScoreCard>
          <ScoreCard
            iconSrc={air}
            value={data.pdr__numbers.achieved_otr_num.toString()}
            metric="Highest OTR achieved to date (ambient)"
          ></ScoreCard>
          <ScoreCard
            iconSrc={water}
            value={data.pdr__numbers.achieved_wvtr_tropical.toString()}
            metric="Highest WVTR achieved to date (tropical)"
          ></ScoreCard>
        </ScorecardsRow>

        <h4>Our current performance potentially unlocks developing packaging for the following products</h4>

        <InternalSubtitle>Glasswing & Clearwing</InternalSubtitle>
        <ScorecardsRow width="20%" className="flex-start">
          <IconTextCard iconSrc={bakery} text="Bakery / bread products" />
          <IconTextCard iconSrc={grainBag} text="Bulk food section" />
          <IconTextCard iconSrc={frozenFood} text="Frozen food" />
          <IconTextCard iconSrc={sugar} text="Confectionary (sugar coated & mints)" />
        </ScorecardsRow>

        <InternalSubtitle>Lemonella & Brimstone</InternalSubtitle>
        <ScorecardsRow width="16%" className="flex-start">
          <IconTextCard iconSrc={chocolateBar} text="Confectionary (chocolate)" />
          <IconTextCard iconSrc={dogFood} text="Dry pet food" />
          <IconTextCard iconSrc={pills} text="Supplements, Dry personal care" />
          <IconTextCard iconSrc={acorn} text="Dried fruits & nuts" />
          <IconTextCard iconSrc={snackBar} text="Snack food" />
        </ScorecardsRow>

        <InternalSubtitle>Bluemorph</InternalSubtitle>
        <ScorecardsRow width="16%">
          <IconTextCard iconSrc={dryFood} text="Dry food" />
          <IconTextCard iconSrc={flour} text="Baking staples" />
          <IconTextCard iconSrc={tea} text="Tea" />
          <IconTextCard iconSrc={coffee} text="Coffee" />
          <IconTextCard iconSrc={dishwasher} text="Washing powder, Dishwashing tabs" />
        </ScorecardsRow>
      </CollapsibleSection>
    </Wrapper>
  );
};

// export const pdr_research = () => {
//   return (
//     <Wrapper>
//       <SectionSubTitle>Software & Data Section on Q</SectionSubTitle>
//       <HalfCardsHalfImage>
//         <div className="cards-container">
//           <ScoreCard
//             iconSrc={research}
//             value={data.pdr__research.technologies_active_research.toString()}
//             metric="research innovations under development"
//           ></ScoreCard>
//           <ScoreCard
//             iconSrc={days}
//             value={data.pdr__research.time_to_project_viability[0].toString()}
//             metric="days project viability assessment time"
//             decreaseValue={data.pdr__research.time_to_project_viability[1].toString()}
//           ></ScoreCard>
//         </div>
//         <div className="image-container">
//           <ImageContainer url={hands} imageWidth="30%"></ImageContainer>
//         </div>
//       </HalfCardsHalfImage>
//     </Wrapper>
//   );
// };

export const pdr_bd = () => {
  return (
    // Usage example:
    <Wrapper>
      <SectionSubTitle>Manufacturing Partners & Procurement</SectionSubTitle>

      <ScorecardsRow width="32%">
        <ScoreCard
          iconSrc={partners}
          value={data.bizdev__values.partners_mapped.toString()}
          increaseValue={data.bizdev__values.partners_mapped_increase.toString()}
          metric={`Partners mapped`}
          sup_metric="1"
        ></ScoreCard>

        <ScoreCard
          iconSrc={suppliers}
          value={data.bizdev__values.suppliers.toString()}
          increaseValue="100"
          metric="screened suppliers and scale-up partners"
        ></ScoreCard>

        <ScoreCard
          iconSrc={partners}
          value={data.bizdev__values.close_partners_suppliers.toString()}
          decreaseValue="3"
          metric="core partners"
        ></ScoreCard>
        <ScoreCard
          iconSrc={partners}
          value={data.bizdev__values.close_partners_scaleup.toString()}
          increaseValue="2"
          metric="scale-up partners"
        ></ScoreCard>
        <ScoreCard iconSrc={quality} value={data.bizdev__values.OTIFRQ.toString()} 
         decreaseValue="3"
        metric={`OTIFRQ`}></ScoreCard>
      </ScorecardsRow>
      <Annotation>
        <sup>1</sup> 85% of which come from Europe, North America or China
      </Annotation>
      <Annotation>
        <sup>2</sup> OTIFRQ - On Time, In Full, At Right Quality - this metric describes the overall quality of our
        procurement system
      </Annotation>
    </Wrapper>
  );
};

export const Versus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 6rem;
  color: #cad9dc;
`;
