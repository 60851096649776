import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface ScoreCardProps {
  iconSrc: string;
  value: string;
  metric: string;
  fontsize?: string;
  scorecard_style?: string;
  sup_val?: string
  sup_metric?: string
  showArrow?: boolean;
  index?:number;
  backgroundColor?: string;
  mobileTitle?: string
}


const ArrowScoreCard: FC<ScoreCardProps> = ({ iconSrc, value, metric, fontsize, scorecard_style, sup_val, sup_metric, index, backgroundColor, mobileTitle}) => {
  return (
    <>
    <Scorecard style={{ zIndex: index, backgroundColor: backgroundColor }}>
        <React.Fragment>
          <MobileTitle>{mobileTitle}</MobileTitle>
          <Value>{value}<Sup>{sup_val}</Sup></Value>
          <MetricContainer>
            <Metric fontsize={fontsize}>{metric}<Sup>{sup_metric}</Sup></Metric>
          </MetricContainer>
          <ScoreCardIconContainer>
                {iconSrc != "" ? (
                    <ScorecardIcon src={iconSrc} alt="icon" />
                ) : (
                    null
                )}
        </ScoreCardIconContainer>
        </React.Fragment>
    </Scorecard>
    </>
  );
};


ArrowScoreCard.defaultProps = {
  fontsize: '16px',
  scorecard_style: "col2", // col3 or col2
  index: 0,
  backgroundColor: "#f4f4f4",
}

export default ArrowScoreCard;

interface ArrowSVGProps {
    fillColor?: string;
  }
  
const ArrowSVG: React.FC<ArrowSVGProps> = ({ fillColor = '#f4f4f4' }) => {
return (
    <svg width="20" height="100%" viewBox="0 0 20 100" preserveAspectRatio="none">
    <polygon points="0,0 20,50 0,100" fill={fillColor} stroke="black" strokeWidth="1" />
    </svg>
);
};


export const MobileTitle = styled.p`
margin-bottom: 0;
text-transform: uppercase;
color: #fff;
padding-left: 10px;
display: none;
@media only screen and (max-width: 768px) {
  display: block;
}
`
export const ArrowTitle = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    text-transform: uppercase;
    font-weight: 600;
    > p:first-child{ color: #FA6432; }
    > p:nth-child(2){ color: #FFC700; }
    > p:nth-child(3){ color: #96D232; }
    > p:nth-child(4){ color: #46B950; }
    > p:last-child{ color: #0064B4; }
    @media only screen and (max-width: 768px) {
      display: none;
    }
  `;

export const ArrowScorecardsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0;
  border: 1px solid #ddd;
  padding: 5px 5px 5px 5px;
  border-radius: 8px;
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const Scorecard = styled.div<{ backgroundColor?: string }>`
  background-color: ${props => props.backgroundColor || "#f4f4f4"};
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  position: relative;
  width: 100%;
  z-index: 9;
  &:not(:last-child) {
    margin-right: -1rem;
    clip-path: polygon(0 0, calc(100% - 1rem) 0, 100% 50%, calc(100% - 1rem) 100%, 0 100%);
  }
  @media only screen and (max-width: 768px) {
      width: 35.5%;
  }
  @media only screen and (max-width: 600px) {
    width:100%;
    margin-right: -1rem;
      clip-path: polygon(0 0, calc(100% - 1rem) 0, 100% 50%, calc(100% - 1rem) 100%, 0 100%);
  }
  
`;

export const ScoreCardIconContainer = styled.div`
  width: 90px;
  position: relative;
  top: 0px;
  right: -12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  z-index: 1;
  overflow: hidden;
  clip-path: circle(50% at center);
  @media only screen and (max-width: 600px) { 
    position: absolute;
    top: 0rem;
    right: 0px;
    height: 100%;
  }
`;
export const ScorecardIcon = styled.img`
    width: 40px;
    flex-shrink: 0;
    svg {
        stroke: rgb(0, 0, 0);
    }
`;

export const Value = styled.div`
  font-size: 25px;
  font-weight: 600;
  color: #FFF;
  padding-left: 10px;
`;

export const Metric = styled.span<{ fontsize?: string }>`
  font-size: ${props => props.fontsize || "20px"};
  display: block;
  color: #FFF;
  padding-left: 10px;
`;

const Sup = styled.sup`
  font-size: 0.6em;
  vertical-align: super;
`;
export const MetricContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;
