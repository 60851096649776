import React from "react";
import CollapsibleSection from "../components/collapsible";
import { LastUpdated, Wrapper, SectionTitle, Annotation, SectionSubTitle, Undertitle, StyledParagraph, GraphAndText, ScordCardsColumn, ScordCardsColumnContainer, TimeLine, InfoColumn, ScoreCardColumnsNoLine } from "../components/sectionElements"
import BubblePlot from "../components/charts/bubble"
import DonutChart from "../components/charts/donut";
import { ScoreCard, ScorecardsRow } from "../components/index";
import * as data from "../data";
import sachet from '../images/001-sachet.svg';
import value from '../images/002-growth.svg';
import market from '../images/006-coins.svg';
import productBrief from '../images/026-agreement.svg';
import customers from '../images/028-customer-behavior.svg';
import smq from '../images/027-tissue-paper.svg';
import sales from '../images/029-deal.svg';
import arrow from '../images/right-arrow-4.svg';
import development from '../images/development.svg';
import improvement from '../images/improvement.svg';


export const last_updated = () => {
    return(
        <LastUpdated>Last updated: 14th December, 2023</LastUpdated>
    )
}

export const commercial__market_data = () => {
    return (
        // Usage example:
        <Wrapper id="section1">
            <SectionTitle>Commercial</SectionTitle> 
            <CollapsibleSection title="Market Context"> 
            {/* <SectionSubTitle>Market Context</SectionSubTitle>     */}
                <ScordCardsColumnContainer>
                    <ScordCardsColumn>
                        <TimeLine>
                            <div>2022</div>
                        </TimeLine>
                        <ScoreCard
                            iconSrc={value}
                            value={data.commercial__numbers.packaging_market_size[0].toString()}
                            metric="Global packaging market"></ScoreCard>
                        <ScoreCard
                            iconSrc={market}
                            value={data.commercial__numbers.global_sustainable_packaging_market.toString()}
                            metric="Global sustainable packaging market"></ScoreCard>
                        <ScoreCard scorecard_style="col3"
                            iconSrc={market}
                            value={data.commercial__numbers.sachet_market_size.toString()}
                            metric="sachets market size"></ScoreCard>
                        <ScoreCard scorecard_style="col3"
                            iconSrc={sachet}
                            value={data.commercial__numbers.sachets_produced_annually.toString()}
                            metric="sachets produced annually"></ScoreCard>
                    </ScordCardsColumn>
                    <InfoColumn>
                        <div><p>4.3% <br/>CAGR</p><img src={arrow} alt="CAGR"/></div>
                        <div><p>10.3% <br/> CAGR</p><img src={arrow} alt="CAGR"/></div>
                        <div><p>0.9% <br/> CAGR</p><img src={arrow} alt="CAGR"/></div>
                        <div><p>0.9% <br/> CARG</p><img src={arrow} alt="CAGR"/></div>
                    </InfoColumn>
                    <ScordCardsColumn>
                        <TimeLine>
                            <div>2030</div>
                        </TimeLine>
                            <ScoreCard
                                    iconSrc={value}
                                    value={data.commercial__numbers.packaging_market_size_2030.toString()}
                                    metric="Global packaging market"></ScoreCard>
                                <ScoreCard
                                    iconSrc={market}
                                    value={data.commercial__numbers.global_sustainable_packaging_market_2030.toString()}
                                    metric="Global sustainable packaging market"></ScoreCard>
                                <ScoreCard scorecard_style="col3"
                                    iconSrc={market}
                                    value={data.commercial__numbers.sachet_market_size_2030.toString()}
                                    metric="sachets market size"
                                    ></ScoreCard>
                                <ScoreCard scorecard_style="col3"
                                    iconSrc={sachet}
                                    value={data.commercial__numbers.sachets_produced_2030.toString()}
                                    metric="sachets produced annually"
                                    ></ScoreCard>
                    </ScordCardsColumn>
                </ScordCardsColumnContainer>
                    
                <Annotation><sup>1</sup> CAGR - Compound annual growth rate</Annotation>
                <Annotation><a rel="noreferrer" href="https://www.marketresearchfuture.com/reports/sustainable-packaging-market-5247"  target="_blank">Data source 1</a></Annotation>
                <Annotation><a rel="noreferrer" href="https://finance.yahoo.com/news/sachet-packaging-market-estimated-worth-013000074.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAHuo7HlYekGhIIfRK1S8Wnt-TzpFiSDMji6mgmUglwSLTt5sJyvQBNoBfRF9h7gcjcYQCZNZDyYy4kTmBtVA0gymmrUPziNNXU-CnqId0kwxEvSO2lAsTe70ZeDk1zXGCnxVBV8zMjNTHHyZI6s6ql6pJgVBytHZxUbWVJwYwA_U"  target="_blank">Data source 2</a></Annotation>
                </CollapsibleSection>
        </Wrapper>
    );
};


export const commercial__sales = () => {
    return (
        <Wrapper>
            <SectionSubTitle>Sales & Revenue</SectionSubTitle>
            <ScordCardsColumnContainer>
                <ScoreCardColumnsNoLine>
                    <p className="column-title">2023<span> (Actuals) - </span>  <span className="number">€232.4k</span></p>
                    <ScoreCard
                        iconSrc={improvement}
                        value={data.sales__revenues.sales__revenues_actual.toString()}
                        metric="Co-Development"></ScoreCard>
                        <ScoreCard
                            iconSrc={sales}
                            value={data.sales__revenues.sales__revenues_actual2.toString()}
                            metric="Pilot Reel Sales"></ScoreCard>
                </ScoreCardColumnsNoLine>
                <ScoreCardColumnsNoLine>
                    <p className="column-title">2024 <span>(Projected) - </span><span className="number">€4m</span></p>
                    <ScoreCard
                        iconSrc={value}
                        value={data.projected_sales__revenues.sales__revenues_projection.toString()}
                        metric="Volume Based Royalties"></ScoreCard>
                    <ScoreCard
                        iconSrc={development}
                        value={data.projected_sales__revenues.sales__revenues_projection2.toString()}
                        metric="Pre-sales (Technology Deployment)"></ScoreCard>
                </ScoreCardColumnsNoLine>
            </ScordCardsColumnContainer>
                {/* <DonutChart data={data.sales__revenues} title="Actuals: 2023 to date" chartId="sales_donut" /> */}
                {/* <DonutChart data={data.projected_sales__revenues} title="2023" chartId="sales_donut" 
                titleX={0} titleY={10}/> */}
       
            <SectionSubTitle>Our Customer Pipeline</SectionSubTitle>
            <ScorecardsRow width="48%">
                <ScoreCard
                iconSrc={productBrief}
                value={data.sales__numbers.customer_product_briefs_received_to_date[0].toString()}
                metric="Number of customer product briefs received to date" increaseValue={data.sales__numbers.customer_product_briefs_received_to_date[1].toString()}></ScoreCard>
                
                <ScoreCard
                iconSrc={customers}
                value={data.sales__numbers.customers_in_pipeline.toString()}
                metric="Active FMCGs in pipeline"></ScoreCard>
        
                <ScoreCard
                iconSrc={smq}
                value={data.sales__numbers.square_meters_packaging.toString()}
                metric="Packaging in pipeline through 2025"></ScoreCard>
               
                <ScoreCard
                iconSrc={sales}
                value={data.sales__numbers.total_sales_pipeline_value.toString()}
                metric={`Total Sales Pipeline Value through 2025`}></ScoreCard>              
            
            </ScorecardsRow>
        </Wrapper>
    );
};

