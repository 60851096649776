// Header.tsx
import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { getAuth, signOut } from 'firebase/auth';
import { logEvent } from "firebase/analytics";
import { analytics } from "../App";



// TypeScript interface for the component props
interface HeaderProps {
    activeSection: string;
    setActiveSection: (section: string) => void;
}

const scrollToElement = (element: HTMLElement | null, offset: number) => {
    if (!element) return;
    const yPosition = element.getBoundingClientRect().top + window.pageYOffset - offset;
    window.scrollTo({ top: yPosition, behavior: 'smooth' });
  };

// Header component
const Navigation = () => {
    const auth = getAuth();
    const [activeSection, setActiveSection] = useState("section1");
    const [currentSection, setCurrentSection] = useState("");
    useEffect(() => {
        const sections = document.querySelectorAll("[data-section]");
        const handleScroll = () => {
            sections.forEach((section) => {
                const rect = section.getBoundingClientRect();
                if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                    setCurrentSection(section.getAttribute("data-section") || "");
                }
            });
        };
        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <NavContainer>
            <NavList>
                <NavItem>
                    <NavLink href="#section1" data-section="section1" onClick={(e) => {
                                e.preventDefault();
                                setActiveSection("section1");
                                const sectionElement = document.getElementById("section1");
                                scrollToElement(sectionElement, 145); // Replace 145 with the desired offset value
                                logEvent(analytics, 'menu_click', {
                                    value: "section1",
                                  });
                            }}>
                        Commercial
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="#section2" data-section="section2" onClick={(e) => {
                                e.preventDefault();
                                setActiveSection("section2");
                                const sectionElement = document.getElementById("section2");
                                scrollToElement(sectionElement, 145); // Replace 145 with the desired offset value
                                logEvent(analytics, 'menu_click', {
                                    value: "section2",
                                  });
                            }}>
                        Product Development & Research
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="#section3" data-section="section3" onClick={(e) => {
                                e.preventDefault();
                                setActiveSection("section3");
                                const sectionElement = document.getElementById("section3");
                                scrollToElement(sectionElement, 145); // Replace 145 with the desired offset value
                                logEvent(analytics, 'menu_click', {
                                    value: "section3",
                                  });
                            }}>
                       Software & Data
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="#section4" data-section="section4" onClick={(e) => {
                                e.preventDefault();
                                setActiveSection("section4");
                                const sectionElement = document.getElementById("section4");
                                scrollToElement(sectionElement, 145); // Replace 145 with the desired offset value
                                logEvent(analytics, 'menu_click', {
                                    value: "section4",
                                  });
                            }}>
                        Finance & People
                    </NavLink>
                </NavItem>
            </NavList>
        </NavContainer>
    );
};

export default Navigation;

const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
}
`;

const NavItem = styled.li`
  margin: 0 1rem;
`;

const NavLink = styled.a`
  color: #fafafa;
  text-decoration: none;
  transition: color 0.3s ease;
  font-weight: 550;
  text-shadow: 0px 0px 3px #971B2A;
  text-transform: uppercase;
  @media only screen and (max-width: 600px) {
    font-size: 0.9rem;
    }

  &:hover {
    font-weight: 550;
    color: #fafafa;
    text-shadow: 0px 0px 10px #fafafa;

  }
`;