import React, { useState } from 'react';
import styled from 'styled-components';
import arrowDown from '../images/arrow-down.svg';
import arrowUp from '../images/arrow-up.svg';


const DisclosureWrapper = styled.div`
border: 1px solid white;
`;

const DisclosureHeader = styled.div`
margin-bottom: 50px;
cursor: pointer;
display: flex;
justify-content: space-between;
align-items: center;
`;

const DisclosureTitle = styled.h2`
margin: 0;
// color: #00A06E;
`;

const DisclosureContent = styled.div`
margin-top: 10px;
`;

const IconImage = styled.img`
width: 30px;
`;

const Line = styled.hr`
border-top: 1px solid #8c8b8b;
text-align: center;
margin-bottom: 40px;
`;

interface CollapsibleSectionProps {
    title: string;
    children: React.ReactNode;
    iconColor?: string;
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({ title, children }) => {
    const [isExpanded, setIsExpanded] = useState(false);


const toggleDisclosure = () => {
    setIsExpanded(!isExpanded);
};

return (
    <DisclosureWrapper>
        <DisclosureHeader onClick={toggleDisclosure}>
            <DisclosureTitle>{title}</DisclosureTitle>
            <span>{isExpanded ? (<IconImage src={arrowDown} alt='Expand' /> ) : (<IconImage src={arrowUp} alt='Collapse' />) }</span>
        </DisclosureHeader>
        {isExpanded && <DisclosureContent>{children}</DisclosureContent>}<Line/>
    </DisclosureWrapper>
);
};

export default CollapsibleSection;