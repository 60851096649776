// Header.tsx
import React, { ReactNode } from "react";
import styled from 'styled-components';
import { getAuth, signOut } from 'firebase/auth';
import { FaSignOutAlt } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import logoutIcon from '../images/logout.svg'
import header from "../images/header_edited.png"

// TypeScript interface for the component props
interface HeaderProps {
    children: ReactNode;
}

// Header component
const Header: React.FC<HeaderProps> = ({ children }) => {
    const location = useLocation();
    const auth = getAuth();
    const LogOutButton = () => {
        return (
            <LogoutIcon>
                <img src={logoutIcon} onClick={() => signOut(auth)}  />
            </LogoutIcon>
        );
    }

    return (
        <HeaderContainer>
            <div className="container">
                <HeaderTitle>one·five <Separator>|</Separator>  Snapshot of the business</HeaderTitle>
            </div>
            <div>
                {location.pathname == '/' && <LogOutButton />}
            </div>
            {children}
        </HeaderContainer>
    );
};

export default Header;

const LogoutIcon = styled.div`
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    border: 2px solid #fafafa;
    padding: 0.6rem;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 600px) {
        width: 40px;
        height: 40px;    
        top: 96px;
        right: 0.5rem;
    }
    > img {
        width: 25px;
        flex-shrink: 0;
        @media only screen and (max-width: 600px) {
            width:20px;
        }
    }
`;

const HeaderContainer = styled.div`
background-image: url(${header});
opacity: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  z-index: 9999;
  padding-top: 20px;
  padding-bottom: 30px;
  height: 145px;
  @media only screen and (max-width: 600px) {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const HeaderTitle = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  color: #fff;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 550;
  text-shadow: 0px 0px 3px #971B2A;
  @media only screen and (max-width: 600px) {
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
}
`;

const Separator = styled.span`
    font-weight: 100
`


