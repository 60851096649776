import { Container, Row as BRow, Col as BCol } from 'react-bootstrap';
import styled from 'styled-components';

/* Both Wrapper and Section Title have a weird behaviour regarding the padding-top 
- how do we make sure they don't overlap with the header?*/
export const Wrapper = styled(Container)`
  padding: 1.5rem;
  @media only screen and (max-width: 600px) {
    padding: 0.5rem;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin: 1rem 0 1.5rem;
  color: #00a06e;
  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
    margin: 1rem 0 1rem;
  }
`;

export const SectionSubTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
  @media only screen and (max-width: 600px) {
    font-size: 1.7rem;
    margin-bottom: 1rem;
  }
`;

export const SectionSubSubTitle = styled.h2`
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
  @media only screen and (max-width: 600px) {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
`;

export const InternalSubtitle = styled.p`
  color: #4c4c4c;
  font-weight: 600;
  margin: 1.5rem 0px -1.5rem;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const Undertitle = styled.h5`
  width: 100px;
  color: black;
  font-weight: 600;
  margin: 1rem 0px -1rem;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  @media (min-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: -0.5rem;
  }
  @media (min-width: 1200px) {
    font-size: 1.4rem;
    margin-bottom: -0.8rem;
  }
`;

export const Row = styled(BRow)`
  // margin-bottom: 15px;
`;

export const Column = styled(BCol)<{ width?: string }>`
  width: ${(props) => props.width || '100%'};
`;

export const Box = styled.div<{ width?: string; height?: string; testing?: boolean }>`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || 'auto'};
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const Text = styled.p`
  font-size: 1.1rem;
  margin: 0;
`;

export const Annotation = styled.p`
  font-size: 0.8rem;
  margin: 0;
  color: grey;
`;

export const Chart = styled.div`
  // height: 300px;
`;

export const StyledParagraph = styled.p`
  font-size: 1.1rem
  weight: normal;
`;

interface HighlightProps extends React.HTMLAttributes<HTMLSpanElement> {
  colorKey?: keyof ColorMap;
  fontSize?: string;
  fontWeight?: string | number;
}

interface ColorMap {
  [key: string]: string;
}

const colorMap: ColorMap = {
  orange: '#FF9800',
  blue: '#0064B4',
  green: '#00A06F',
  darkblue: '#2F4858',
  lightblue: '#9CE2C6',
};

export const Highlight = styled.span<HighlightProps>`
  color: ${({ colorKey }) => colorMap[colorKey || 'orange']};
  font-size: ${({ fontSize }) => fontSize || 'inherit'};
  font-weight: ${({ fontWeight }) => fontWeight || '500'};
`;

export const Value = styled.span`
  font-size: 1.4rem;
  font-weight: '500';
`;

export const LastUpdated = styled.div`
  font-size: 1rem;
  margin: 1rem 0 1.5rem;
  color: grey;
  @media only screen and (max-width: 600px) {
    font-size: 1 rem;
    margin: 1rem 0 1rem;
  }
`;

export const UnorderedList = styled.li`
  margin-left: 1rem;
  li&:nth-child(1) {
    margin-top: 0.8rem;
  }
  li&:last-child {
    margin-bottom: 0.8rem;
  }
`;

export const TwoChartsSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  > div {
    width: 100%;
  }
  @media only screen and (min-width: 900px) {
    justify-content: space-between;
    flex-direction: row > div {
      width: 45%;
    }
  }
`;

// GRAPH AND TEXT STYLES
export const GraphAndText = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
    border-bottom: 1px solid #eee;
    @media only screen and (max-width: 600px) {
        border: none;
        > div {
            max-width: 350px;
            margin: 0 auto;
        }
    }
    > p {
        width: 40%;
        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }
    @media only screen and (max-width: 600px) {
        flex-direction: column;
        &:nth-child(odd) {
            flex-direction: column-reverse;
}
        }
    }
    
`;

// // GRAPH AND CARDS
export const GraphAndCards = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
flex-wrap: wrap;
@media only screen and (max-width: 992px) {
  flex-direction: column;
}
}
> div {
  width: 48%;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
}
`;

// TIME LINE SCORE CARDS
export const TimeLine = styled.div`
  margin: 0rem 0px 2rem;
  font-size: 2rem;
  color: rgb(60, 101, 116);
  z-index: 9;

  > div {
    width: 50%;
    margin-left: 33px;
    display: flex;
    align-items: center;
    color: #000;
    text-transform: uppercase;
    &::before {
      content: '';
      display: inline-flex;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: #00a06e;
      border: 7px solid #f4f4f4;
      margin-right: 11px;
      flex-shrink: 0;
    }
  }
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 10%;
  position: relative;
  margin-top: 5rem;
  @media only screen and (max-width: 600px) {
    display: none;
  }
  > div {
    flex: 1;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    flex-direction: column;
    justify-content: center;
    > p {
      font-size: 12px;
      line-height: 1.2;
      margin-bottom: 0;
      color: #666;
      font-weight: 300;
      position: relative;
      text-align: center;
    }
    > img {
      max-width: 80%;
    }
  }
`;

export const TimeArrowWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  margin: 0rem 0px 3rem;
`;

export const TimeArrow = styled.div`
  padding: 10px;
  border-radius: 8px;
  display: inline-block;
  text-align: center;
`;

export const ArrowLine = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: calc(50% + 10px);
  height: 5px;
  background-color: #46b950;
  fill-color: #46b950;
  z-index: -1;
`;

export const ArrowHead = styled.div`
  position: absolute;
  right: -1px;
  top: calc(50% + 2px);
  width: 15px;
  height: 20px;
  background-image: linear-gradient(to bottom right, #46b950 49%, transparent 52%),
    linear-gradient(to top right, #46b950 49%, transparent 52%);
  background-position: bottom right, top right;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  z-index: -1;
`;

export const ScordCardsColumnContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 2rem 0;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const ScordCardsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  position: relative;
  &::after {
    content: '';
    width: 0px;
    height: 100%;
    position: absolute;
    border: 1px solid #00a06e;
    top: 12px;
    left: 45px;
    z-index: 2;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

// COMPANIES LOGOS GRID
export const CompaniesLogos = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  align-items: center;
  margin-bottom: 4rem;

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
  }

  > div {
    background: #f4f4f4;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
  }
  > .div1 {
    > img {
      width: 120px;
      flex-shrink: 0;
    }
  }
  > .div2 {
    > img {
      width: 170px;
      flex-shrink: 0;
    }
  }
  > .div3 {
    > img {
      width: 100px;
      padding: 10px;
      flex-shrink: 0;
    }
  }
  > .div4 {
    > img {
      width: 120px;
      flex-shrink: 0;
    }
  }
  > .div5 {
    > img {
      width: 120px;
      flex-shrink: 0;
    }
  }
  > .div6 {
    > img {
      width: 120px;
      flex-shrink: 0;
    }
  }
  > .div7 {
    > img {
      width: 90px;
      padding: 10px;
      flex-shrink: 0;
    }
  }
  > .div8 {
    > img {
      width: 120px;
      flex-shrink: 0;
    }
  }
  > .div9 {
    > img {
      width: 90px;
      padding: 15px;
      flex-shrink: 0;
    }
  }
  > .div10 {
    > img {
      width: 120px;
      flex-shrink: 0;
    }
  }
  > .div11 {
    > img {
      width: 120px;
      flex-shrink: 0;
    }
  }
  > .div12 {
    > img {
      width: 120px;
      flex-shrink: 0;
    }
  }
  > .div13 {
    > img {
      width: 120px;
      flex-shrink: 0;
    }
  }
  > .div14 {
    > img {
      width: 120px;
      flex-shrink: 0;
    }
  }
  > .div15 {
    > img {
      width: 120px;
      flex-shrink: 0;
    }
  }
  > .div16 {
    > img {
      width: 80px;
      padding: 10px;
      flex-shrink: 0;
    }
  }
`;

// THERMOMETER
export const Thermometer = styled.div`
  margin: 3rem 4rem 12rem;
  position: relative;
  @media only screen and (max-width: 768px) {
    margin: 2rem 0 12rem;
  }
`;
export const ThermoTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-transform: uppercase;
  font-weight: 600;
  > p:first-child {
    color: #0064b4;
  }
  > p:nth-child(2) {
    color: #f0d200;
  }
  > p:last-child {
    color: #01a06e;
  }
`;
export const ThermoValues = styled.div`
    display:flex;
    border: 1px solid #ddd;
    border-radius: 30px;
    padding: 3px;
    > div {
      width: calc(100% / 9);
      text-align: center;
      padding: 0.5rem 0;
      color: #fff;
      z-index: 1;
    }
    > .one {background: #0064B4; border-top-left-radius: 23px;
      border-bottom-left-radius: 23px; border-right: 2px solid #fff;}
    > .two {background: #3483c3; border-right: 2px solid #fff;
      position: relative;
      &::after {
        content: "";
        width: 0px;
        height: 42px;
        position: absolute;
        border: 1px solid #3483c3;
        top: 35px;
        left: 50%;
        z-index: 2;
      }
    }
    > .three {background: #67a2d2; border-right: 2px solid #fff;
      position: relative;
      &::after {
        content: "";
        width: 0px;
        height: 86px;
        position: absolute;
        border: 1px solid #67a2d2;
        top: 35px;
        left: 97.5%;
        z-index: 2;
      }
    }
    > .four {background: #f3db34; border-right: 2px solid #fff;
      position: relative;
      &::after {
        content: "";
        width: 0px;
        height: 42px;
        position: absolute;
        border: 1px solid #f3db34;
        top: 35px;
        left: 50%;
        z-index: 2;
      }
    }
    > .five {background: #f6e467; border-right: 2px solid #fff;
      position: relative;
      &::after {
        content: "";
        width: 0px;
        height: 155px;
        position: absolute;
        border: 1px solid #f6e467;
        top: 35px;
        right: 97.5%;
        z-index: 1;
      }
    }
    > .six {background: #f9ed99; border-right: 2px solid #fff;
      position: relative;
      &::after {
        content: "";
        width: 0px;
        height: 86px;
        position: absolute;
        border: 1px solid #f9ed99;
        top: 35px;
        right: 97%;
        z-index: 2;
      }
    }
    > .seven {
      background: #65c6a8; 
      border-right: 2px solid #fff;
      position: relative;
      &::after {
        content: "";
        width: 0px;
        height: 135px;
        position: absolute;
        border: 1px solid #65c6a8;
        top: 35px;
        left: 50%;
        z-index: 1;
      }
    }
  > .eight {background: #32b48a; border-right: 2px solid #fff;}
  > .nine {
    background: #01a06e; 
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
    position: relative;
    &::after {
      content: "";
      width: 0px;
      height: 204px;
      position: absolute;
      border: 1px solid #01a06e;
      top: 35px;
      left: 50%;
      z-index: 0;
    }
  
  }
}
  `;
export const ThermoTextLine1 = styled.div`
  position: absolute;
  right: 77.5%;
  bottom: -3.8rem;
  border: 1px solid #ddd;
  border-radius: 30px;
  padding: 0.1rem 0.5rem;
  font-size: 15px;
  background-color: #fff;
  z-index: 2;
`;
export const ThermoTextLine2 = styled.div`
  position: absolute;
  bottom: -6.5rem;
  right: 60.5%;
  border: 1px solid #ddd;
  border-radius: 30px;
  padding: 0.1rem 0.5rem;
  font-size: 15px;
  background-color: #fff;
  z-index: 2;
`;
export const ThermoTextLine3 = styled.div`
  position: absolute;
  bottom: -3.8rem;
  right: 57%;
  border: 1px solid #ddd;
  border-radius: 30px;
  padding: 0.1rem 0.5rem;
  font-size: 15px;
  background-color: #fff;
  z-index: 2;
`;
export const ThermoTextLine4 = styled.div`
  position: absolute;
  bottom: -10.5rem;
  right: 48%;
  border: 1px solid #ddd;
  border-radius: 30px;
  padding: 0.1rem 0.5rem;
  font-size: 15px;
  background-color: #fff;
  z-index: 2;
`;
export const ThermoTextLine5 = styled.div`
  position: absolute;
  bottom: -8rem;
  right: 23%;
  border: 1px solid #ddd;
  border-radius: 30px;
  padding: 0.1rem 0.5rem;
  font-size: 15px;
  background-color: #fff;
  z-index: 2;
`;
export const ThermoTextLine6 = styled.div`
  position: absolute;
  bottom: -5.7rem;
  right: 23%;
  border: 1px solid #ddd;
  border-radius: 30px;
  padding: 0.1rem 0.5rem;
  font-size: 15px;
  background-color: #fff;
  z-index: 2;
`;
export const ThermoTextLine7 = styled.div`
  position: absolute;
  bottom: -3.5rem;
  right: 23%;
  border: 1px solid #ddd;
  border-radius: 30px;
  padding: 0.1rem 0.5rem;
  font-size: 15px;
  background-color: #fff;
  z-index: 2;
`;
export const ThermoTextLine8 = styled.div`
  position: absolute;
  bottom: -13rem;
  right: 1%;
  border: 1px solid #ddd;
  border-radius: 30px;
  padding: 0.1rem 0.5rem;
  font-size: 15px;
  background-color: #fff;
  z-index: 2;
`;
export const ThermoTextLine9 = styled.div`
  position: absolute;
  bottom: -10.8rem;
  right: 1%;
  border: 1px solid #ddd;
  border-radius: 30px;
  padding: 0.1rem 0.5rem;
  font-size: 15px;
  background-color: #fff;
  z-index: 2;
`;

export const ThermoTextLine10 = styled.div`
  position: absolute;
  bottom: -4.8rem;
  right: 40%;
  border: 1px solid #ddd;
  border-radius: 30px;
  padding: 0.1rem 0.5rem;
  font-size: 15px;
  background-color: #fff;
  z-index: 2;
`;

// CARDS AND IMAGES
export const HalfCardsHalfImage = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  .image-container {
    width: 48%;
    margin-bottom: 1.5rem;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
    > div {
      background-size: cover;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    }
  }
  .cards-container {
    flex-basis: 49%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
      flex-basis: 100%;
    }
  }
}
  `;


  export const ScoreCardColumnsNoLine = styled.div`
    display: flex;
    flex-direction: column;
    width: 48%;
    margin-bottom: 3rem;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
    .column-title {
      font-size: 1.3rem;
      font-weight: 600;
      text-transform: uppercase;
      span {
        font-weight: 300;
        font-size: 1.2rem;
         font-style: italic;
        // text-transform: uppercase;
        &.number {
          color: #00a06e;
          font-weight: 700;
          font-size: 1.4rem;
        }
      }
    }
  `