import React, { FC } from 'react';
import styled from 'styled-components';


interface IconTextCardProps {
  iconSrc: string;
  text: string;
}

const IconTextCard: FC<IconTextCardProps> = ({ iconSrc, text }) => {
  return (
    <IconCard>
        <React.Fragment>
          <IconHolder src={iconSrc} alt="icon" />
          <TextHolder>
            {text}
          </TextHolder>
        </React.Fragment>
    </IconCard>
  );
};

export default IconTextCard;

export const IconCard = styled.div`
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    margin-bottom: -0.5rem;
    overflow: hidden;
    width: 100%;
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TextHolder = styled.div`
    font-weight: 300;
    line-height: 1.3;
    font-size: 14px;
    width: 100%;
    background: #f4f4f4;
    border-top: 1px solid #ddd;
    padding: 0.5rem;
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: center;
    color: #444;
    text-align: center;
    @media only screen and (max-width: 600px) {
      font-size: 16px;
      padding: 1rem 4rem 1rem 0;
    }
`;

export const IconHolder = styled.img`
    width: 90px;
    padding: 1rem;
    @media only screen and (max-width: 600px) {
      width: 68px;
    }
`;

