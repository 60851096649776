import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCMore from 'highcharts/highcharts-more';
import styled from 'styled-components';
  import AnnotationsModule from 'highcharts/modules/annotations';


AnnotationsModule(Highcharts);
HCMore(Highcharts);

type Coordinate = [number, number];

function sigmoid(x: number): number {
  const steepness = 1; // Increase steepness
  const inflectionPoint = 12; // Keep inflection point at 50
  return 100 / (1 + Math.exp(-steepness * (x - inflectionPoint)));
}




function generateSigmoidValues(): { sectionData: Coordinate[][], fullCurve: Coordinate[] } {
  const yellowRange = { start: 0, end: 9 };
  const greenRange = { start: 9, end: 16 };
  const redRange = { start: 16, end: 27 };
  const step = 1; // Adjust step for finer or coarser data points

  const generateSection = (start: number, end: number): Coordinate[] => {
    const sectionData: Coordinate[] = [];
    for (let x = start; x <= end; x += step) {
      sectionData.push([x, sigmoid(x)]);
    }
    return sectionData;
  };

  const yellowData = generateSection(yellowRange.start, yellowRange.end);
  const greenData = generateSection(greenRange.start, greenRange.end);
  const redData = generateSection(redRange.start, redRange.end);

  // Merging the three sections into one continuous curve
  const fullCurve = [...yellowData, ...greenData, ...redData];

  return { sectionData: [yellowData, greenData, redData], fullCurve };
}


// Example usage
const { sectionData, fullCurve } = generateSigmoidValues();


function offsetAndNormalizeCoordinates(
  coordinates: Coordinate[],
  offset: number,
  offsetX: boolean
): Coordinate[] {
  return coordinates.map(coordinate => {
    let [x, y] = coordinate;

    // Applying offset to the specified axis
    if (offsetX) {
      x += -offset;
    } else {
      y += offset;
    }

    // Normalizing the values to the range [0, 100]
    x = Math.max(0, Math.min(x, 100));
    y = Math.max(0, Math.min(y, 100));

    return [x, y] as Coordinate;
  });
}


// Example usage
const exampleCoordinates: Coordinate[] = [[10, 20], [30, 40], [50, 60]];
const offsetValue = -3;
const isOffsetX = true; // Set to false to offset y-axis

const result = offsetAndNormalizeCoordinates(exampleCoordinates, offsetValue, isOffsetX);

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 100%;
  
`;

interface PointAnnotation {
  xAxis: number;
  yAxis: number;
  x: number;
  y: number;
  text: string;
  bgColor?: string;
}

const StageCurve: React.FC = () => {
  const annotations: PointAnnotation[] = [
    // {
    //   xAxis: 0,
    //   yAxis: 0,
    //   x: 12,
    //   y: 53,
    //   text: '1500 products (AI generated - ready for selection)',
    // },
    {
      xAxis: 0,
      yAxis: 0,
      x: 6,
      y: 16,
      text: '8 products',
      bgColor: '#eee'
    },
    {
      xAxis: 0,
      yAxis: 0,
      x: 14,
      y: 60,
      text: '4 products',
      bgColor: '#eee'
    },
    // {
    //   xAxis: 0,
    //   yAxis: 0,
    //   x: 11,
    //   y: 113,
    //   text: '2 products (Ready for License)',
    //   bgColor: '#eee'
    // },
    {
      xAxis: 0,
      yAxis: 0,
      x: 21,
      y: 125,
      text: '2 products',
      bgColor: '#eee'
    },
    {
      xAxis: 0,
      yAxis: 0,
      x: 0,
      y: 12,
      text: '10.000 m2',
    },
    {
      xAxis: 0,
      yAxis: 0,
      x: 0,
      y: 102,
      text: '1 million m2',
    },
    
  ];

  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'line',
      margin: [60, 80, 120, 70],
      scrollablePlotArea: {
        minWidth: 600,
        
      },
    },
    title: {
       text: '',
    },
    xAxis: {
      accessibility: {
        rangeDescription: 'stage',
      },
      tickInterval: 1,
      min: 0,
      labels: {
        enabled: true,
         rotation: -40,
      },
      categories: ['', '', '', '','',  '3-6 month','','','','','', '', '', '', '1 month','' ,'','','','','', '', '5-7 years', '', '','', '','','']
    },
    yAxis: [
      {
        title: {
          text: 'Scale',
        },
        labels: {
          format: '{value}%',
          enabled: false
        },
        
        type: 'linear',
        min: 0,
      },
      {
        title: {
          text: 'Scale',
        },
        opposite: true,
        type: 'logarithmic',
      },
      
    ],
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: 0,
      },
      area: {
        fillOpacity: 0,
      },
    },
   annotations: annotations.map(({ xAxis, yAxis, x, y, text, bgColor }) => ({
      draggable: '',
      labelOptions: {
        shape: 'rect',
        borderRadius: 5,
        backgroundColor: '#fafafa',
        borderWidth: 0,
        borderColor: '#ddd',
        verticalAlign: 'top',
        style: {
          fontSize: '12px',
          fontWeight: '300',
          width: 200,
          whiteSpace: 'break-spaces',
         
        },
        y: 15,
      },
      labels: [
        {
          point: {
            xAxis,
            yAxis,
            x,
            y,
          },
          text,
          backgroundColor: bgColor
        },
      ],
    })), 
    series: [
      {
        name: 'ML assisted rapid product development',
        color: '#ffd740',
        type: 'area',
        lineWidth: 4,
        data: sectionData[0],         
        marker: {enabled: false},
      },
      // {
      //   name: 'Stage',
      //   type: 'area',
      //   data: offsetAndNormalizeCoordinates(fullCurve, offsetValue, isOffsetX), 
      //   marker: {enabled: false},
      //   lineColor: 'black',
      //   color: 'black',
      //   lineWidth: 4,
      // },
      
      {
        name: 'Product reproducibility',
        color: '#32b48a',
        type: 'area',
        data: sectionData[1],
        lineWidth: 4,
        marker: {enabled: false},
      },
      {
        name: 'Licence Based Royalties',
        color: '#FC8C79',
        type: 'area',
        data:  sectionData[2], 
        lineWidth: 4,
        marker: {enabled: false},
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
  };

  return (
    <Container>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </Container>
  );
};

export default StageCurve;