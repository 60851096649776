import React from 'react';
import styled from 'styled-components';
import CollapsibleSection from '../components/collapsible';
import { Wrapper, SectionSubTitle, HalfCardsHalfImage } from '../components/sectionElements';
import { ScoreCard, ScorecardsRow } from '../components/index';
import { ImageContainer } from '../components/imageContainer';
import StackedBarChart from '../components/charts/stackedbarchart';
import * as data from '../data';
import research from '../images/015-search.svg';
import days from '../images/017-calendar.svg';
import hands from '../images/labhands.jpg';

export const pdr_research = () => {
  return (
    <Wrapper id="section3">
      <SectionSubTitle>Software & Data Section on Q</SectionSubTitle>
      <StackedBarChart chartId="test" title="" unit="" data={data.sad__featurecount}/>
      {/* <HalfCardsHalfImage>
        <div className="cards-container">
          <ScoreCard
            iconSrc={research}
            value={data.pdr__research.technologies_active_research.toString()}
            metric="research innovations under development"
          ></ScoreCard>
          <ScoreCard
            iconSrc={days}
            value={data.pdr__research.time_to_project_viability[0].toString()}
            metric="days project viability assessment time"
            decreaseValue={data.pdr__research.time_to_project_viability[1].toString()}
          ></ScoreCard>
        </div>
        <div className="image-container">
          <ImageContainer url={hands} imageWidth="30%"></ImageContainer>
        </div>
      </HalfCardsHalfImage> */}
    </Wrapper>
  );
};
