import { createGlobalStyle } from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';

export const GlobalStyles = createGlobalStyle`
  // Import Bootstrap styles
  html, body {
    margin: 0;
    padding: 0;

  }
  *, *::after, *::before {
    box-sizing: border-box;
  }
  body {
    background: #FFFFFF;
    color: #000;
    font-family: 'Inter';
    text-rendering: optimizeLegibility;
    font-weight: 400;
  }
  `;

export default GlobalStyles