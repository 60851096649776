import React from "react";


/*
----------------------------- COMMERCIAL SECTION -----------------------------
*/

/*
Commercial
*/

export const commercial__numbers = {
    packaging_market_size: ["$1 Tn", "8"],
    global_sustainable_packaging_market: "$317.4 Bn",
    sachet_market_size: "$10 Bn",
    sachets_produced_annually: "1 Tn",
    //
    packaging_market_size_2030: "$1.4 Tn",
    global_sustainable_packaging_market_2030: "$737.6 Bn",
    sachet_market_size_2030: "$15 Bn",
    sachets_produced_2030: "1.7 Tn",
    //
    price_per_sachet: "$0.01",
    packaging_average_CAGR: "5.2%",
    number_customers: 16,
    global_packaging_market_cagr: "4.3%",
    global_sustainable_packaging_market_cagr: "10.3%",
    sachets_market_cagr: "5.9%",
} /* in report */

/*
Sales
*/
export const sales__revenues = {
  sales__revenues_actual: '€100k',
  sales__revenues_actual2: '€132.4k',
}

// [
//       { name: '€100,000 in co-development (ACV)', y: 100000.0, color: '#00A06E' },
//           { name: '€2,400 in product sales (ARR)', y: 2400.0, color: '#9CE2C6' },
//           ]; /* not in report */

export const projected_sales__revenues = {
  sales__revenues_projection: '€2.4M',
  sales__revenues_projection2: '€1.6M',
}

// [
//   { name: '€900,000 in co-development (ACV)', y: 100000.0, color: '#00A06E' },
//   { name: '€100,000 in product sales (ARR)', y: 2400.0, color: '#65C6A8' },
// ]; /* in report */

export const sales__numbers = {
    customer_product_briefs_received_to_date: ["63","6"], /* in report */
    customers_in_pipeline: "17", /* in report */
    square_meters_packaging:"198.5M m²", /* in report */
    total_sales_pipeline_value: "€60.4M", /* in report */
}

/*
----------------- PRODUCT, RESEARCH AND BUSINESS OPS -----------------
*/

/* 
Product Development
*/

export const pdr__impact = {
    emission_savings_lower_range: "37%",
    emission_savings_upper_range: "75%",
}

export const pdr__numbers = {
  time_to_prototype : "less than 100 days",
  achieved_otr: "<0.05",
  achieved_wvtr: "<1",
  achieved_wvtr_tropical: "<2",
  achieved_otr_num: 0.05,
  achieved_wvtr_num: 1,
  prototypes_developed:7,
  trials_performed:8,
  technologies_preprototype:13
}

export const pdr__pipeline = [
  {
    metric: "technologies", value: ">1M",
},
{
  metric: "patent families", value: "550k",
},
{
  metric: "assets", value: "13",
},
{
  metric: "solutions", value: "11",
},
{
  metric: "coatings", value: "3",
}
]

export const pdr_tech_dev_sankey = [
    ['Patents', 'Evaluation', 1000000],
    ['Other', 'Evaluation', 694],
    ['Evaluation', 'Selection', 550000],
    ['Selection', 'Product Design', 20],
    ['Product Design', 'Prototype', 13],
    ['Product Design', 'Pre-prototype', 7],
    ['Evaluation', 'Research', 14 ],
    ['Research', 'Ongoing', 3 ],
    ['Research', 'Killed', 3 ],
  ];


export const bizdev__values = {
  partners_mapped: 746, /* in report */
  partners_mapped_increase: "119", /* in report */
    close_partners_suppliers: 15, /* in report - Number of processing partners (course of dealing) */
    close_partners_scaleup: 13,
    geographical_percentage_partners: "85%",
    suppliers: "191", /* in report, the log: Number of tech partners (mapped & replied) */
    OTIFRQ: "92%", /* in report */
}

export const pdr__research = {
  technologies_active_research: 5, /* in report */
  time_to_project_viability: ["<15","45"], /* in report */
  cultivation_capacity: 3720 /* in report */
}

export const sad__featurecount = [
  {name: "software", data: [3,9,14,19]},
  {name: "ai", data:[0,0,1,3]},
  {name: "data", data: [0,4,7,10]}
]

/*
----------------- PEOPLE AND FINANCE -----------------
*/

export const people__numbers = {
  active_employees:46, /* in report */
  nationalities_represented:20, /* in report */
  self_described_female:"50%", /* in report */
  leadership_years: 137, /* in report */
  blt_female_ratio: "55%", /* in report */
  leadership_female_ratio: "42%", /* in report */
  leavers_since_inception: 8,
  leavers_since_inception_delta: 3,
}

/*
finances
*/
export const finance_raised = [
    { name: '€10,5M Venture Funding', y: 10500000.0, color: '#00A06E' },
    { name: '€850,000 Loans', y: 350000.0, color: '#96D232' },
    { name: '€232,495 Grants', y: 232495.0, color: '#1164B4' },
]; /* in report */

export const finance__numbers = {
  current_fume_date: "March '24", /* in report */
  monthly_burn_rate: "€546k", /* in report */
  salaries_paid_to_date: "€1.6M",  /* in report */
  salaries_paid_in_last_quarter: "€952k",  /* in report */
  ebitda: "-€4.77M",
  tangible: "€505k",
  intangible: "€1.2M"
}