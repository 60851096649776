import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import GoogleButton from 'react-google-button';
import { FaTimes } from 'react-icons/fa';
import { Header } from "../components/index";
import { getAuth, GoogleAuthProvider, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { useNavigate } from "react-router-dom"
import { logEvent } from "firebase/analytics";
import { analytics } from "../App";


const COMPANY_DOMAIN = process.env.REACT_APP_COMPANY_DOMAIN as string;

const Login = (props: object) => {
    const auth = getAuth();
    const navigate = useNavigate();
    const [authing, setAuthing] = useState(false);
    const [email, setEmail] = useState('');
    const [sending, setSending] = useState(false);
    const [error, setError] = useState('');
    const [showInputs, setShowInputs] = useState(false);

    const signInWithGoogle = async () => {
        setAuthing(true);
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
          .then(response => {
            const userEmail = response.user.email;
            if (userEmail) {
              const emailDomain = userEmail.split('@')[1];
              if (emailDomain !== COMPANY_DOMAIN) {
                logEvent(analytics, 'unauthorised_sso_user', {
                  value: emailDomain
                });
                // If the email domain doesn't match, sign the user out
                auth.signOut();
                console.log(`Access restricted to users with an authorised email address.`);
                setAuthing(false);
              } else {
                // If the email domain matches, allow the user to proceed
                logEvent(analytics, 'authorised_sso_user', {
                  value: emailDomain
                });
                navigate('/');
              }
            } else {
              // If userEmail is null, show an error message
              console.log('Unable to retrieve user email address. Please try again.');
              logEvent(analytics, 'unauthorised_sso_user', {
                value: ""
              });
              setAuthing(false);
            }
          })
          .catch(error => {
            console.log("error");
            console.log(error);
            setAuthing(false);
          });
      };

      const OnlyPasswordSignIn = () => {
        const auth = getAuth();
        const [email, setEmail] = useState('report-guest@one-five.com');
        const [password, setPassword] = useState('');
        const [authing, setAuthing] = useState(false);
        const [errorMessage, setErrorMessage] = useState('');

        const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            setAuthing(true);
            setErrorMessage('');

            signInWithEmailAndPassword(auth, email, password)
                .then(() => {
                    console.log('signed in with password');
                    logEvent(analytics, 'authorised_account_user');
                    navigate('/');
                })
                .catch(error => {
                  console.log('unauthorised_password');
                    console.error(error);
                    setErrorMessage('Sorry, the password is incorrect. Please try again');
                    setAuthing(false);
                });
        };

        const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
        };

        const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
        };

        return (
            <Wrapper>
                <Form onSubmit={handleSubmit}>
                        <Label htmlFor="password">Password:</Label>
                        <Input type="password" id="password" value={password} onChange={handlePasswordChange} />

                        {
                            errorMessage
                            && <Message>{errorMessage}</Message>}
                        <Button type="submit" disabled={authing}>
                            Sign in with password
                        </Button>
                    
                </Form>
            </Wrapper>
        );
    };

    const EmailPasswordSignIn = () => {
        const auth = getAuth();
        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        const [authing, setAuthing] = useState(false);
        const [errorMessage, setErrorMessage] = useState('');

        const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            setAuthing(true);
            setErrorMessage('');

            signInWithEmailAndPassword(auth, email, password)
                .then(() => {
                    console.log('signed in with email and password');
                    logEvent(analytics, 'unauthorised_account_user');
                    navigate('/');
                })
                .catch(error => {
                  console.log('unauthorised_account_user');
                    console.error(error);
                    setErrorMessage(error.message);
                    setAuthing(false);
                });
        };

        const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
        };

        const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
        };

        return (
            <Wrapper>
                <Form onSubmit={handleSubmit}>
                    <div>
                        <Label htmlFor="email">Email:</Label>
                        <Input type="email" id="email" value={email} onChange={handleEmailChange} />

                        <Label htmlFor="password">Password:</Label>
                        <Input type="password" id="password" value={password} onChange={handlePasswordChange} />

                        {
                            errorMessage
                            && <Message>{errorMessage}</Message>}
                        <Button type="submit" disabled={authing}>
                            Sign in with email
                        </Button>
                    </div>
                </Form>
            </Wrapper>
        );
    };

    const ResetPassword = () => {
        const [email, setEmail] = useState('');
        const [showResetForm, setShowResetForm] = useState(false);
        const [resetEmail, setResetEmail] = useState('');

        const handleForgotPasswordClick = () => {
            setShowResetForm(true);
        };

        const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
        };

        const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            sendPasswordResetEmail(auth, email)
                .then(() => {
                    console.log('Sent a reset email');
                    logEvent(analytics, 'password_reset', {
                      account: email
                    });

                })
                .catch(error => {
                    console.error(error);
                });
        };

        return (
            <ResetWrapper>

                {showResetForm ? (
                    <Wrapper>
                        <Form onSubmit={handleSubmit}>
                            <CloseButton onClick={() => setShowResetForm(false)} />
                            <Label htmlFor="email">Email:</Label>
                            <Input type="email" id="resetemail" value={email} onChange={handleEmailChange} />
                            <Button type="submit">Reset Password</Button>
                        </Form></Wrapper>
                ) : <ResetPasswordLink onClick={() => setShowResetForm(true)}> Forgot Password?</ResetPasswordLink>

                }

            </ResetWrapper>
        );
    }

    return (
        <div>
            <Header>
                <div></div>
            </Header>
            <Container>
                <OnlyPasswordSignIn></OnlyPasswordSignIn>
                <br />
                <ResetPassword></ResetPassword>
                <br />
                <GoogleButton onClick={() => signInWithGoogle()} disabled={authing}>
                    Sign In with Google
                </GoogleButton>
                <LoginDisclosure>Only for one-five accounts</LoginDisclosure>
            </Container>
        </div>
    );
};
export default Login

const CloseButton = styled(FaTimes)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const LoginDisclosure = styled.p`
  color: #4385f4;
  font-size: 0.9rem;
  margin-top: 0.5rem;
`;

const ResetPasswordLink = styled.a`
  color: #222;
  font-size: 16px;
  text-decoration: none;
  margin-top: 10px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ResetWrapper = styled.div`
  position: relative;
  padding-bottom: 20px;
`;

const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 300px;
margin: 0 auto;
border: 1px solid #ddd;
padding: 20px;
box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);


Label {
  margin-top: 1rem;
}

Input {
  margin: 0 8px 1rem;
}

Button {
  margin:auto;
}

`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

`;

const Button = styled.button`
background-color: rgb(66, 133, 244);
color: rgb(255, 255, 255);
height: 50px;
width: 240px;
border: none;
text-align: center;
box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px 0px;
font-size: 16px;
line-height: 48px;
display: block;
border-radius: 1px;
transition: background-color 0.218s ease 0s, border-color 0.218s ease 0s, box-shadow 0.218s ease 0s;
font-family: Roboto, arial, sans-serif;
cursor: pointer;
user-select: none;

  &:hover {
    background-color: #f1f3f4;
    border-color: #f1f3f4;
    color: rgba(0, 0, 0, 0.87);
  }

  &:active {
    background-color: #eeeeee;
    border-color: #eeeeee;
  }
`;

const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  margin: 8px;
  width: 100%;
  box-sizing: border-box;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  align-self: flex-start;
`;

const Message = styled.p`
  text-align: center;
  color: #F44336;
  font-size: 14px;
  line-height: 1.2;
  position: relative;
  top: -9px;
`;
